<template>
<div></div>
</template>
<script>
export default {
  props: {
    section: {
      type: String,
      default: "home"
    },
    status: {
      type: String,
      default: "paid"
    }
  },
  created() {
    this.$router.push({path: "/dashboard/" + this.section + "/00/" + this.status});
  },
}
</script>
<style>
</style>

    